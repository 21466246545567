import * as THREE from 'three'
import { gsap } from 'gsap'
import Experience from '../Experience.js'

export default class Skills {
    constructor() {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.sizes = this.experience.sizes
        this.camera = this.experience.camera

        // Setup
        this.color = this.experience.color

        // Mouse movement
        this.mouse = this.experience.camera.mouse
        this.target = new THREE.Vector2()
        this.windowHalf = new THREE.Vector2(this.sizes.width / 2, this.sizes.height / 2)

        // HTML elements
        this.lineSkillLogiciel = document.getElementById('lineLogiciel')
        this.textSkillLogiciel = document.querySelectorAll('.skill-text-logiciel')

        this.lineSkillLanguage = document.getElementById('lineLanguage')
        this.textSkillLanguage = document.querySelectorAll('.skill-text-language')

        // Set circle
        this.setCircle()

        // Set circle movement
        document.addEventListener('mousemove', this.onMouseMove.bind(this), false)
    }

    showSkills() {
        if (this.sizes.width < 1024) {
            // Show Logiciel
            gsap.to(this.lineSkillLogiciel, {
                duration: 0.2,
                scaleX: 1,
                ease: 'power1.in',
                delay: 0.2,
                onComplete: () => {
                    setTimeout(() => {
                        for (const textSkill of this.textSkillLogiciel) {
                            textSkill.style.left = 0
                        }
                    }, 500)
                }
            })

            // Show Language
            gsap.to(this.lineSkillLanguage, {
                duration: 0.2,
                scaleX: 1,
                ease: 'power1.in',
                delay: 0.6,
                onComplete: () => {
                    setTimeout(() => {
                        for (const textSkill of this.textSkillLanguage) {
                            textSkill.style.left = 0
                        }
                    }, 500)
                }
            })
        } else {
            // Show Logiciel
            gsap.to(this.lineSkillLogiciel, {
                duration: 0.2,
                scaleY: 1,
                ease: 'power1.in',
                delay: 0.2,
                onComplete: () => {
                    setTimeout(() => {
                        for (const textSkill of this.textSkillLogiciel) {
                            textSkill.style.left = 0
                        }
                    }, 500)
                }
            })

            // Show Language
            gsap.to(this.lineSkillLanguage, {
                duration: 0.2,
                scaleY: 1,
                ease: 'power1.in',
                delay: 0.6,
                onComplete: () => {
                    setTimeout(() => {
                        for (const textSkill of this.textSkillLanguage) {
                            textSkill.style.left = 0
                        }
                    }, 500)
                }
            })
        }

    }

    hideSkills() {
        if (this.sizes.width < 1024) {
            // Hide Logiciel
            for (const textSkill of this.textSkillLogiciel) {
                textSkill.style.left = '-500px'
            }

            gsap.to(this.lineSkillLogiciel, {
                duration: 0.2,
                scaleX: 0,
                ease: 'power1.in',
                delay: 0.2
            })

            // Hide Language
            for (const textSkill of this.textSkillLanguage) {
                textSkill.style.left = '-500px'
            }
            gsap.to(this.lineSkillLanguage, {
                duration: 0.2,
                scaleX: 0,
                ease: 'power1.in',
                delay: 0.2
            })
        } else {
            // Hide Logiciel
            for (const textSkill of this.textSkillLogiciel) {
                textSkill.style.left = '-200px'
            }

            gsap.to(this.lineSkillLogiciel, {
                duration: 0.2,
                scaleY: 0,
                ease: 'power1.in',
                delay: 0.2
            })

            // Hide Language
            for (const textSkill of this.textSkillLanguage) {
                textSkill.style.left = '-200px'
            }
            gsap.to(this.lineSkillLanguage, {
                duration: 0.2,
                scaleY: 0,
                ease: 'power1.in',
                delay: 0.2
            })
        }
    }

    setCircle() {
        // Circle
        const geometry = new THREE.RingGeometry(15, 18.5, 30)
        this.material = new THREE.MeshStandardMaterial({
            color: this.color.one,
            wireframe: true,
            lightMapIntensity: 0
        })
        this.mesh = new THREE.Mesh(geometry, this.material)
        this.mesh.position.set(140, -8, -1)
        this.scene.add(this.mesh)
    }

    onMouseMove(event) {
        this.mouse.x = (event.clientX - this.windowHalf.x)
        this.mouse.y = (event.clientY - this.windowHalf.y)

    }

    resize() {
        this.hideSkills()
        this.showSkills()
    }

    update() {
        // If skills
        if (this.camera.skillsReady === true) {
            // Rotate circle
            this.mesh.rotation.z += 0.001

            // If not touch pointer
            if (!window.matchMedia("(pointer: coarse)").matches) {
                // Move circle
                this.target.y = (1 - this.mouse.y) * 0.02

                this.mesh.position.x += 0.02 * (140 + this.target.y - this.mesh.position.x)
            } else {
                // Position circle
                this.mesh.position.set(150, 0, -1)
            }
        }
    }
}