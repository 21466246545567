import * as THREE from 'three'
import Swiper from 'swiper'
import { Navigation } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import Experience from '../Experience.js'


export default class Project {
    constructor() {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.sizes = this.experience.sizes
        this.camera = this.experience.camera

        // Setup
        this.color = this.experience.color

        // Mouse movement
        this.mouse = this.experience.camera.mouse
        this.target = new THREE.Vector2()
        this.windowHalf = new THREE.Vector2(this.sizes.width / 2, this.sizes.height / 2)

        // HTML elements
        this.btnProjects = document.querySelectorAll('.button-project')
        this.btnClose = document.getElementById('closeProject')

        this.detailProject = document.getElementById('detailProject')

        this.detailValinouet = document.getElementById('detailValinouet')
        this.detailFbms = document.getElementById('detailFbms')
        this.detailFirm = document.getElementById('detailFirm')
        this.detailHumanis = document.getElementById('detailHumanis')
        this.detailDenturo = document.getElementById('detailDenturo')
        this.detailLabyrinth = document.getElementById('detailLabyrinth')

        this.detailLogo = document.querySelectorAll('.detail-logo')
        this.detailTitle = document.querySelectorAll('.detail-title')
        this.detailDevelop = document.querySelectorAll('.developed')
        this.detailTasks = document.querySelectorAll('.tasks')
        this.detailDescription = document.querySelectorAll('.description-text')
        this.detailImages = document.querySelectorAll('.detail-images')

        // Set circle
        this.setCircle()

        // Set swipers
        this.setSwipers()

        // Close project
        this.btnClose.addEventListener('click', () => this.hideDetailProject())

        // Set circle movement
        document.addEventListener('mousemove', this.onMouseMove.bind(this), false)
    }

    setSwipers() {
        //Valinouet
        this.swiperValinouetHTML = document.querySelector('.swiper-vali')
        this.nextVali = document.querySelector('.next-vali')
        this.prevVali = document.querySelector('.prev-vali')
        this.swiperValinouet = new Swiper(this.swiperValinouetHTML, {
            modules: [Navigation],
            speed: 1000,
            spaceBetween: 80,
            simulateTouch: false,
            navigation: {
                nextEl: this.nextVali,
                prevEl: this.prevVali
            }
        })

        //Fbms
        this.swiperFbmsHTML = document.querySelector('.swiper-fbms')
        this.nextFbms = document.querySelector('.next-fbms')
        this.prevFbms = document.querySelector('.prev-fbms')
        this.swiperFbms = new Swiper(this.swiperFbmsHTML, {
            modules: [Navigation],
            speed: 1000,
            spaceBetween: 80,
            simulateTouch: false,
            navigation: {
                nextEl: this.nextFbms,
                prevEl: this.prevFbms
            }
        })

        //Firm
        this.swiperFirmHTML = document.querySelector('.swiper-firm')
        this.nextFirm = document.querySelector('.next-firm')
        this.prevFirm = document.querySelector('.prev-firm')
        this.swiperFirm = new Swiper(this.swiperFirmHTML, {
            modules: [Navigation],
            speed: 1000,
            spaceBetween: 80,
            simulateTouch: false,
            navigation: {
                nextEl: this.nextFirm,
                prevEl: this.prevFirm
            }
        })

        //Humanis
        this.swiperHumanisHTML = document.querySelector('.swiper-humanis')
        this.nextHumanis = document.querySelector('.next-humanis')
        this.prevHumanis = document.querySelector('.prev-humanis')
        this.swiperHumanis = new Swiper(this.swiperHumanisHTML, {
            modules: [Navigation],
            speed: 1000,
            spaceBetween: 80,
            simulateTouch: false,
            navigation: {
                nextEl: this.nextHumanis,
                prevEl: this.prevHumanis
            }
        })

        //Denturo Boulevard
        this.swiperDenturoHTML = document.querySelector('.swiper-denturo')
        this.nextDenturo = document.querySelector('.next-denturo')
        this.prevDenturo = document.querySelector('.prev-denturo')
        this.swiperDenturo = new Swiper(this.swiperDenturoHTML, {
            modules: [Navigation],
            speed: 1000,
            spaceBetween: 80,
            simulateTouch: false,
            navigation: {
                nextEl: this.nextDenturo,
                prevEl: this.prevDenturo
            }
        })

        //Labyrinth
        this.swiperLabyrinthHTML = document.querySelector('.swiper-labyrinth')
        this.nextLabyrinth = document.querySelector('.next-labyrinth')
        this.prevLabyrinth = document.querySelector('.prev-labyrinth')
        this.swiperLabyrinth = new Swiper(this.swiperLabyrinthHTML, {
            modules: [Navigation],
            speed: 1000,
            spaceBetween: 80,
            simulateTouch: false,
            navigation: {
                nextEl: this.nextLabyrinth,
                prevEl: this.prevLabyrinth
            }
        })
    }

    showProjects() {
        this.btnProjects.forEach(btnProject => {
            btnProject.classList.add('visible')
        })
    }

    hideProjects() {
        this.btnProjects.forEach(btnProject => {
            btnProject.classList.remove('visible')
        })
    }

    showDetailProject(project) {
        // Show details
        this.detailProject.classList.add('visible')
        if (project === 'valinouet') { this.detailValinouet.classList.add('visible') }
        else if (project === 'fbms') { this.detailFbms.classList.add('visible') }
        else if (project === 'firm') { this.detailFirm.classList.add('visible') }
        else if (project === 'humanis') { this.detailHumanis.classList.add('visible') }
        else if (project === 'denturo') { this.detailDenturo.classList.add('visible') }
        else if (project === 'labyrinth') { this.detailLabyrinth.classList.add('visible') }
        this.btnClose.classList.add('visible')

        // Show details elements
        setTimeout(() => {
            this.detailLogo.forEach(logo => {
                logo.classList.add('active')
            })
        }, 3750)
        setTimeout(() => {
            this.detailTitle.forEach(title => {
                title.classList.add('active')
            })
        }, 4000)
        setTimeout(() => {
            this.detailDevelop.forEach(develop => {
                develop.classList.add('active')
            })
        }, 4600)
        setTimeout(() => {
            this.detailTasks.forEach(tasks => {
                tasks.classList.add('active')
            })
        }, 4800)
        setTimeout(() => {
            this.detailDescription.forEach(description => {
                description.classList.add('active')
            })
        }, 5000)
        setTimeout(() => {
            this.detailImages.forEach(image => {
                image.classList.add('active')
            })
        }, 5200)
    }

    hideDetailProject() {
        // Hide details
        this.detailProject.classList.remove('visible')
        this.detailValinouet.classList.remove('visible')
        this.detailFbms.classList.remove('visible')
        this.detailFirm.classList.remove('visible')
        this.detailHumanis.classList.remove('visible')
        this.detailDenturo.classList.remove('visible')
        this.detailLabyrinth.classList.remove('visible')
        this.btnClose.classList.remove('visible')

        // Hide details element
        setTimeout(() => {
            this.detailLogo.forEach(logo => {

                logo.classList.remove('active')
            })
            this.detailTitle.forEach(title => {

                title.classList.remove('active')
            })
            this.detailDevelop.forEach(develop => {
                develop.classList.remove('active')
            })
            this.detailTasks.forEach(tasks => {
                tasks.classList.remove('active')
            })
            this.detailDescription.forEach(description => {

                description.classList.remove('active')
            })
            this.detailImages.forEach(image => {
                image.classList.remove('active')
            })
        }, 800)
    }

    setCircle() {
        // Circle mesh
        const geometry = new THREE.RingGeometry(15, 18.5, 30)
        this.material = new THREE.MeshStandardMaterial({
            color: this.color.one,
            wireframe: true,
            lightMapIntensity: 0
        })
        this.mesh = new THREE.Mesh(geometry, this.material)
        this.mesh.position.set(0, 148, -1)
        this.scene.add(this.mesh)
    }

    onMouseMove(event) {
        this.mouse.x = (event.clientX - this.windowHalf.x)
        this.mouse.y = (event.clientY - this.windowHalf.y)
    }

    update() {
        // If project
        if (this.camera.projectReady === true) {
            // Rotate circle
            this.mesh.rotation.z -= 0.001

            // If not touch pointer
            if (!window.matchMedia("(pointer: coarse)").matches) {
                // Move circle
                this.target.x = - (1 - this.mouse.x) * 0.02
                this.target.y = (1 - this.mouse.y) * 0.05

                this.mesh.position.x += 0.02 * (this.target.y - this.mesh.position.x)
                this.mesh.position.y += 0.02 * (148 + this.target.x - this.mesh.position.y)
            }
            else {
                // Position circle
                this.mesh.position.set(0, 148, -1)
            }
        }
    }
}